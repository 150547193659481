<br>
<br>
<maintenance-alert-placeholder></maintenance-alert-placeholder>
<notification-alert-placeholder></notification-alert-placeholder>
<other-alert-placeholder></other-alert-placeholder>
<h1>{{'Title' | translate }}</h1>
<hr/>

<div *ngIf="showFrequentlyViewed">
  <h2 class="frequentTitle">Most Frequently Viewed</h2>
  <div id="frequentViews">
    <cvs-loader-overlay-container [isLoading]="showSpinner">
      <ul id="ul_top_hypers">
        <li *ngFor="let frequent of frequentViews">
          <div class="imageContainer">
            <a id="frequentImage" href="{{frequent.url}}">
              <img alt="frequent image" class="imageView" [src]="frequent.image">
            </a>
          </div>
          <div class="dashboard_name_word_wrap">
            {{frequent.name}}
          </div>
        </li>
      </ul>
    </cvs-loader-overlay-container>
  </div>
</div>
<app-useful-links *ngIf="showUsefulLinks"></app-useful-links>
